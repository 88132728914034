import React from 'react'
import Layout from '../components/layout'
import logo from '../assets/images/flotherm/ft-logo-white-full-trim.svg'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <div className="flotherm-logo">
            <img src={logo} alt="Flotherm TCD" />
          </div>
          <div>
            <h2>Not Found</h2>
            <p>The requested page doesn&#39;t exist.</p>
          </div>
        </header>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
